<template>
  <div>
    <isHeader ref="isHeader" />
    <div
      ref="scrollBox"
      style="height: 100vh; overflow-y: auto; overflow-x: hidden"
    >
      <commonTop
        :info="{
          title: '智慧随访管理平台',
          desc: '华信互联借助人工智能和医疗大数据技术，将健康宣教内容智能化的推送至患者端，协助医疗和社区构建数据可追溯、可监控的智能型、闭环式宣教管理平台。',
        }"
        :img="require('@/assets/SmartFollowup/banner.png')"
      >
        <!-- 插槽 -->
        <!-- <button>在线咨询</button> -->
        <button @click="$store.commit('changeAppointModal', true)">
          预约体验
        </button>
      </commonTop>
      <!--  -->
      <isTab current="智慧随访管理平台" :info="info" />
      <isFooter />
    </div>
  </div>
</template>

<script>
import isHeader from "@/components/header/index.vue";
import isFooter from "@/components/footer/index.vue";
import commonTop from "@/components/commonTop/index.vue";
import isTab from "@/components/ProductTab/tab.vue";
export default {
  name: "SmartFollowup",
  data() {
    return {
      info: {
        partOne: {
          title: "产品设计理念",
          sub: "聚焦业务需求  深度共建随访管理平台",
          swiper: [
            {
              bg: require("@/assets/SmartFollowup/01.png"),
              icon: `
                            <svg width='100%' height='100%' viewBox='0 0 87 88' fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g clip-path="url(#clip0_1415_29163)">
                            <path d="M30.1133 13.9883H18.0195" stroke="white" stroke-width="2" stroke-miterlimit="10"/>
                            <path d="M24.293 7.71875V20.2604" stroke="white" stroke-width="2" stroke-miterlimit="10"/>
                            <path d="M32.9873 22.2821C37.6407 17.4911 37.5292 9.83489 32.7382 5.18149C27.9472 0.528099 20.291 0.639639 15.6376 5.43062C10.9842 10.2216 11.0958 17.8778 15.8868 22.5312C20.6777 27.1846 28.3339 27.073 32.9873 22.2821Z" stroke="white" stroke-width="2" stroke-miterlimit="10"/>
                            <path d="M70.2236 82.3856C74.877 77.5946 74.7655 69.9384 69.9745 65.285C65.1835 60.6316 57.5273 60.7432 52.874 65.5341C48.2206 70.3251 48.3321 77.9813 53.1231 82.6347C57.9141 87.2881 65.5703 87.1766 70.2236 82.3856Z" stroke="white" stroke-width="2" stroke-miterlimit="10"/>
                            <path d="M53.8542 44.0007H69.0833C77.1458 44.0007 83.8646 37.2819 83.8646 28.7715C83.8646 20.709 77.1458 13.9902 68.6354 13.9902L35.9375 14.4382" stroke="white" stroke-width="2" stroke-miterlimit="10"/>
                            <path d="M49.3753 74.0098L16.6774 74.4577C8.61491 74.4577 1.44824 67.7389 1.44824 59.6764C1.44824 51.6139 8.16699 44.4473 16.2295 44.4473H36.3857" stroke="white" stroke-width="2" stroke-miterlimit="10"/>
                            <path d="M38.6247 59.2292L27.8747 61.9167C27.4268 61.9167 27.4268 61.9167 27.4268 61.4688L29.6663 50.7188L55.1976 25.1875L63.708 33.6979L38.6247 59.2292Z" stroke="white" stroke-width="2" stroke-miterlimit="10"/>
                            </g>
                            <defs>
                            <clipPath id="clip0_1415_29163">
                            <rect width="87" height="88" fill="white"/>
                            </clipPath>
                            </defs>
                            </svg>
                            `,
              name: "随访路径规划",
            },
            {
              bg: require("@/assets/SmartFollowup/02.png"),
              icon: `
                            <svg width="100%" height="100%" viewBox="0 0 89 99" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M28.123 20.8473C31.4307 9.60114 37.3846 2.32422 44 2.32422C54.5846 2.32422 63.8461 23.4935 63.8461 49.2935C63.8461 53.2627 63.8461 56.5704 63.1846 59.8781" stroke="white" stroke-width="2" stroke-miterlimit="10"/>
                            <path d="M59.8774 77.7384C56.5697 88.9845 50.6158 96.923 44.0005 96.923C33.4158 96.923 24.1543 75.7538 24.1543 49.9538C24.1543 45.9845 24.3385 41.8077 25 38.5" stroke="white" stroke-width="2" stroke-miterlimit="10"/>
                            <path d="M11.5843 48.631C3.64581 40.031 -0.323424 31.431 2.98427 25.4772C8.27658 16.2156 31.4304 18.8618 53.9227 32.0925C57.2304 34.0772 60.5381 36.0618 63.1843 38.0464" stroke="white" stroke-width="2" stroke-miterlimit="10"/>
                            <path d="M75.7539 48.6309C84.3539 57.8924 88.3231 67.1539 85.0154 72.4463C79.7231 81.7078 56.5693 79.0616 34.077 65.8309C30.7693 64.5078 27.4616 62.5232 24.8154 60.5386" stroke="white" stroke-width="2" stroke-miterlimit="10"/>
                            <path d="M44 71.1221C47.3077 69.799 50.6154 67.8144 53.9231 65.8297C76.4154 52.599 90.3077 34.7374 85.0154 25.4759C81.7077 20.1836 72.4462 18.8605 60.5385 21.5067" stroke="white" stroke-width="2" stroke-miterlimit="10"/>
                            <path d="M44.0004 26.8008C40.6927 28.7854 37.385 30.1085 34.0773 32.0931C25.4773 37.3854 17.5389 43.3392 12.2465 49.2931C3.64655 57.8931 -0.322686 67.1546 2.98501 73.1085C6.2927 79.0623 15.5542 79.7239 28.1235 77.0777" stroke="white" stroke-width="2" stroke-miterlimit="10"/>
                            <path d="M51.2771 39.3691H34.0771V58.5538H51.2771V39.3691Z" stroke="white" stroke-width="2" stroke-miterlimit="10"/>
                            <path d="M42 40.0312V46.6466L44.6462 44.662L45.9692 46.6466V40.0312H42Z" fill="white"/>
                            </svg>

                            `,
              name: "智能知识库",
            },
          ],
        },
        partTwo: {
          title: "产品功能介绍",
          sub: "通过智能化手段赋能医院随访  助力医院智慧化建设",
          list: [
            {
              title: "随访方案管理",
              desc: "定制化的随访路径设置，精细化、科学化的随访管理，科室化的随访结果统计与数据分析。",
            },
            // {
            //   title: "随访方案管理",
            //   desc: "定制化的随访路径设置，精细化、科学化的随访管理，科室化的随访结果统计与数据分析。",
            // },
          ],
          right_img: require("@/assets/SmartFollowup/visitManage.png"),
        },
      },
    };
  },
  mounted() {
    let that = this;
    this.$refs.scrollBox.addEventListener("scroll", function (e) {
      if (e.target.scrollTop > 80) {
        that.$refs.isHeader.setScrollBg(true);
      } else {
        that.$refs.isHeader.setScrollBg(false);
      }
    });
  },
  components: {
    commonTop,
    isHeader,
    isFooter,
    isTab,
  },
};
</script>

<style>
</style>